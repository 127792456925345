export const isArrayOfInt = (value: any) => {
  if (Array.isArray(value)) {
    return value.every(element => typeof element === "number");
  }
};

export const getApiUrl = () => {
  switch (import.meta.env.MODE) {
    case "test":
      return "https://test.api.jasperdental.fr";

    case "staging":
      return "https://staging.api.jasperdental.fr";

    case "production":
      return "https://api.jasperdental.fr";

    default:
      return "http://localhost:5050";
  }
};
