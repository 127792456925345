import React, { useEffect, useState } from "react";
import {
  useQuery,
} from "@apollo/client";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Table } from "@mantine/core";

import {
  PrecedentComponent,
  SearchBar,
} from "@jasper/shared";

import {
  GET_LAST_UNREAD_HELP_COMMENT_OF_EACH_ORDER,
} from "/@/gql/";
import { getImagesFromFiles, getNotImagesFiles } from "@jasper/shared/utils/file.utils";
import {IconPaperclip, IconPhoto} from "@tabler/icons-react"
import { socket } from '../../socket'

const __TAKE__ = 10;

const HelpList = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageNumber = parseInt(searchParams.get("page") ?? "0", 10);
  const skip = pageNumber * __TAKE__;

  const [searchText, setSearchText] = useState<string>("");

  const { data, refetch } = useQuery(
    GET_LAST_UNREAD_HELP_COMMENT_OF_EACH_ORDER,
    {
      variables: {
        args: {
          skip: skip,
          take: __TAKE__,
          searchInput: searchText,
        }
      },
      fetchPolicy: "network-only",
    }
  );
  
  // useEffect(() => {
  //   socket.on('notification', () => {
  //     refetch();
  //   })
  // }, []);

  // const [markAsUnread] = useMutation(SET_LAST_CHAT_OF_ORDER_AS_UNREAD);
  
  return (
    <> 
      <div style={{ paddingBottom: "1rem", display: "flex" }}>
        <PrecedentComponent/>
        <SearchBar
          searchText={searchText}
          setSearchText={(value: string) => setSearchText(value)}
          onConfirm={() => navigate(`/home?search=${searchText ?? ""}&status=ALL`)}
        />
      </div>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Order reference</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Last message</Table.Th>
            <Table.Th>Patient</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data && (data?.getHelpMsgList?.notification ?? []).map((helpMsg) => {
            return (
              <Table.Tr key={helpMsg.order.id} style={helpMsg.read === false ? {fontWeight: 'bold'} : {}}>
                <Table.Td>{helpMsg.order.orderReference}</Table.Td>
                <Table.Td>{helpMsg.comment === "%Order_Addon_File%" ? "New files": "New help message"}</Table.Td>
                <Table.Td>{helpMsg.order.status}</Table.Td>
                <Table.Td>
                  { helpMsg.comment !== "%Order_Addon_File%" &&(
                      helpMsg.comment.length > 50 ?
                        helpMsg.comment.slice(0,50) + '...'
                        : helpMsg.comment
                    )
                  }
                  {(helpMsg.files !== null && getImagesFromFiles(helpMsg.files).length > 0) && 
                    <span style={{display:'flex'}}>
                      <span>{getImagesFromFiles(helpMsg.files).length} x</span> <IconPhoto/>
                    </span>
                  }
                  {(helpMsg.files !== null && getNotImagesFiles(helpMsg.files).length > 0) && 
                    <span style={{display:'flex'}}>
                      <span>{getNotImagesFiles(helpMsg.files).length} x</span> <IconPaperclip/>
                    </span>
                  }
                </Table.Td>
                <Table.Td>{helpMsg.order.patient.firstName} {helpMsg.order.patient.lastName}</Table.Td>
                <Table.Td>
                  {
                    new Date(helpMsg.createdAt).toDateString() === new Date().toDateString() ?
                      new Date(helpMsg.createdAt).toLocaleTimeString().split(':').splice(0,2).join(':')
                      : new Date(helpMsg.createdAt).toLocaleDateString()
                  }
                </Table.Td>
                <Table.Td style={{display: 'flex', gap: '3px', width:'max-content'}}>
                  <Button onClick={() => navigate(`/orders/${helpMsg.order.id}`, { state: { from: "HELP_LIST" } })}>
                    View order
                  </Button>
                </Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      </Table>
      {data && ((data?.getHelpMsgList?.notification ?? []).length > 0) &&
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div>
            {pageNumber > 0 &&
              <Button
                onClick={() => {
                  navigate(`/help-demands?page=${pageNumber - 1}`)
                }}
              >
                Previous page
              </Button>
            }
          </div>
          <div style={{ marginTop: "1rem" }}>
            Page {pageNumber + 1}
          </div>
          <div>
            {(data?.getHelpMsgList?.notification ?? []).length % __TAKE__ === 0
              && data?.getHelpMsgList?.count !== skip + __TAKE__
              &&
              <Button
                onClick={() => {
                  navigate(`/help-demands?page=${pageNumber + 1}`)
                }}
              >
                Next page
              </Button>
            }
          </div>
        </div>
      }
    </>
  );
};

export default HelpList;
