import React,{ useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Space,
} from "@mantine/core";
import { MantineProvider } from '@mantine/core';
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  SearchBar,
  PrecedentComponent,
} from "@jasper/shared";

import OrderViewPatientComponent from '../components/OrderViewPatientComponent';
import OrderViewStepperComponent from '../components/OrderViewStepperComponent';
import OrderViewSelectedTeeth from '../components/OrderViewSelectedTeeth';
import OrderViewOrderDetail from '../components/OrderViewOrderDetail';
import HeaderOrderView from '../components/HeaderOrderView';
import LabsBreadcrumbs from '../components/labsBreadcrumbs';
import {Chat} from '@jasper/shared/stories/Chat/Chat';

const OrderViewPage = ({
  orderComments,
  order,
  user,
  refetch,
  setMessage,
  messages,
  sendMessage,
  filesToSend,
  setFilesToSend,
  loading,
  hasMore,
  fetchMoreMessages,
  scrollBottom,
  fileWithPath,
  refetchFiles,
  setPictureToMarkAsRead
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchText, setSearchText] = useState<string>("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if ((order?.products ?? []).length > 0) {
      setSelectedProduct(order?.products[0]);
    }
  }, [order?.products]);

  if (!order){
    return null;
  }

  return (
    <MantineProvider>
      <div style={{ paddingBottom: "1rem", display: "flex" }}>
        <PrecedentComponent/>
        <SearchBar
          searchText={searchText}
          setSearchText={(value) => setSearchText(value)}
          onConfirm={() => navigate(`/home?search=${searchText}&status=ALL`)}
        />
      </div>
      <LabsBreadcrumbs orderReference={order?.orderReference} orderId={order?.id}  navigate={(href) => navigate(href)} user={user} searchText={searchParams.get("search") ?? ""}/>
      <Box
        p="lg"
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
        })}
      >
        <HeaderOrderView order={order} user={user} refetch={refetch} refetchFiles={refetchFiles} />
        <div style={{display:'grid', gridTemplateColumns: 'repeat(3, 1fr)', width:'100%', gap: '20px'}}>
          <div style={{ gridColumn: '1 / 3'}}>
            <Grid
              gutter="md"
              sx={{flexGrow: 1, minHeight: "fit-content", maxHeight: "30%"}}
            >
              <OrderViewPatientComponent order={order} user={user} refetch={refetch}/>
              <OrderViewStepperComponent order={order} user={user}/>
            </Grid>
            <Space h="md" />
            <Grid
              gutter="md"
              sx={{flexGrow: 1}}
            >
              <OrderViewSelectedTeeth order={order} selectedProduct={selectedProduct} />
              <OrderViewOrderDetail 
                setPictureToMarkAsRead={setPictureToMarkAsRead}
                orderComments={orderComments}
                fileWithPath={fileWithPath} 
                order={order} 
                selectedProduct={selectedProduct} 
                setSelectedProduct={setSelectedProduct} 
                user={user} 
                refetch={refetch} />
            </Grid>
          </div>
          <div style={{ gridColumn: '3 / 3', display: 'grid'}}>
            <Chat 
              messages={messages}
              currentUserGroup={user?.userGroupId}
              setMessageToSend={setMessage}
              send={sendMessage}
              filesToSend={filesToSend}
              setFilesToSend={setFilesToSend}
              loading={loading}
              hasMore={hasMore}
              fetchMoreMessages={fetchMoreMessages}
              scrollBottom={scrollBottom}
            />
          </div>
        </div>
      </Box>
    </MantineProvider>
  );
};

export default OrderViewPage;
