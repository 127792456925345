import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Menu, Button as MantimeButton, Table, TextInput, Modal } from '@mantine/core';
import { io } from "socket.io-client";

import { Button } from '/@/composants/';
import {
  GET_ALL_ORDERS_FOR_STAFF,
  UPDATE_STATUS_AND_ADD_PRODIVER_GROUP_ID_FOR_MANY_ORDER
} from '/@/gql/';
import {
  getTodayTimestamp,
} from "/@/utils/date.utils";
import { Order } from '/@/types/interfaces';
import { UseAuth } from '/@/contexts';
import { socket } from '../socket'

import {
  OrderStatus,
  UPDATE_MANY_ORDER,
  isUserProvider,
  isUserStaff,
  isImplant,
  isOrderWithNoAttachement,
  isPAC,
  SearchBar,
  PrecedentComponent,
} from '@jasper/shared';
import { notifications } from '@mantine/notifications';
import SendOrderToSupplierModal from '@jasper/shared/components/modals/sendOrderToSupplierModal';
import { GET_ALL_PROVIDERS } from '@jasper/shared/gql/users';
import { IconArrowLeft, IconArrowRight, IconClick } from '@tabler/icons-react'
import "./home.css";

const LIMIT_PAGINATION_QUANTITY = 20;


const OrderListTable = (
  {
    data,
    navigate,
    status,
    setListOrderToUpdate,
    toggleList,
    user,
    selectedValues,
    selectAll,
    searchText,
  }
) => {  
  const checkAllCheckbox = useRef(null)
  const getBackgroundColor = (order: Order) => {
    if (isPAC(order)){
      return ({ backgroundColor: "blue", color: "white" })
    };
    if ((order?.redo ?? []).length > 0){
      return ({ backgroundColor: "purple", color: "white" })
    }
    if (isImplant(order) && order?.comment){
      return ({backgroundColor: "red", color: "white"});
    }
    if (isImplant(order)){
      return ({backgroundColor: "darkred", color: "white"});
    }
    if ((order?.orderComment ?? []).length > 0){
      return ({backgroundColor: "lightcoral", color: "white"});
    }
    return ("");
  };

  const displayLastUpdate = (dt: date) => {
      const lastUpdateInDays = Math.round(Math.abs((dt - new Date()) / (24 * 60 * 60 * 1000)));
      if (lastUpdateInDays >= 1){
        return (dt.toDateString())
      }
      return (dt.toLocaleTimeString());    
  }

  useEffect(()=>{
    setListOrderToUpdate([])
    checkAllCheckbox.current.checked = false
  }, [status])

  const checkAll = () => {
    if (checkAllCheckbox.current.checked === true) {
      const checkboxes = document.getElementsByName('selectOrder');
      const orderIds = [...checkboxes].map((box) => {
        return box.value
      })
      selectAll(orderIds)
    } else {
      selectAll([])
    }
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <label
              className='label-checkbox'
              htmlFor="checkAll"
            >
              <input
                id='checkAll'
                ref={checkAllCheckbox}
                type='checkbox'
                onChange={(e) => checkAll()}
              />
            </label>
          </Table.Th>
          <Table.Th>Note</Table.Th>
          <Table.Th>Reference</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Doctor</Table.Th>
          <Table.Th>Patient</Table.Th>
          <Table.Th>Product</Table.Th>
          {isUserStaff(user) &&
            <>
              <Table.Th>Delivery Date</Table.Th>
              <Table.Th>Last update</Table.Th>
            </>
          }
          {isUserProvider(user) &&
            <Table.Th>Last shipping date</Table.Th>
          }
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {(data ?? []).map((order: Order) => (
          <Table.Tr
            style={{ cursor: "pointer" }}
            key={order.id}
          >
            <Table.Td>
              { (((order?.orderComment.filter(comment => comment.translatedComment === null) ?? []).length === 0)
                || order?.status !== OrderStatus.CONFIRMED) && 
                <label
                  className='label-checkbox'
                  htmlFor={`check-${order.id}`}
                >
                  <input
                    id={`check-${order.id}`}
                    type='checkbox' 
                    value={order.id}
                    name='selectOrder'
                    checked={selectedValues.includes(order.id)}
                    onChange={(e) => toggleList(order.id) }
                  />
                </label>
              }
            </Table.Td>
            <Table.Td
              onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}
              style={getBackgroundColor(order)}
            >
              {(order?.isRush ?? false) && "RUSH "}
              {(order?.isPhysicalPrint ?? false) && "PHYSICAL PRINT "}
              {((order?.orderComment ?? []).length > 0) && " COMMENT "}
              {isImplant(order) && "IMPLANT "}
              {isOrderWithNoAttachement(order) && "NO ATTACHEMENT "}
              {isPAC(order) && "PAC "}
              {((order?.redo ?? []).length > 0) && "REDO"}
            </Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{order.orderReference}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{order.status}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>Dr {order?.user?.firstName} {order?.user?.lastName}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{order?.patient?.firstName} {order?.patient?.lastName}</Table.Td>
            <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{(order.products ?? []).lenght > 1 ? "Multiple" : order.products[0]?.productType}</Table.Td>
            {isUserStaff(user) &&
              <>
                <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{order.deliveryDate ? new Date(order.deliveryDate).toDateString() : "-"}</Table.Td>
                <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{displayLastUpdate(new Date(order.updatedAt ?? order.createdAt))}</Table.Td>
              </>
            }
            {isUserProvider(user) &&
              <Table.Td onClick={() => navigate(`/orders/${order.id}?search=${searchText}`)}>{order?.lastShippingDate ? new Date(order?.lastShippingDate).toDateString() : "-"}</Table.Td>
            }
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  ); 
};

const todayTimestamp = getTodayTimestamp();

const Home = () => {

  const [listOrderToUpdate, setListOrderToUpdate] = useState<string[]>([]);
  const [updateOrderStatusAndAddProvider] = useMutation(UPDATE_STATUS_AND_ADD_PRODIVER_GROUP_ID_FOR_MANY_ORDER);
  const [updateOrderStatus] = useMutation(UPDATE_MANY_ORDER);
  const [openSendToSupplierModal, setOpenSendToSupplierModal] = useState(false);
  const { data: allProviders } = useQuery(GET_ALL_PROVIDERS);

  const toggleList = (id : string) => {
    const foundOrder = listOrderToUpdate.find(val => val === id)
    if(foundOrder !== undefined) {
      setListOrderToUpdate(listOrderToUpdate.filter(val => val !== id))
    }
    else{
      setListOrderToUpdate([...listOrderToUpdate,id])
    }
  };

  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState<string>("");

  const navigate = useNavigate();

  const { user } = UseAuth();

  const getStatus = () => {
    if (searchParams.get('status')){
      return searchParams.get('status');
    }
    if (isUserProvider(user)){
      return OrderStatus.PRODUCING;
    };
    if (isUserStaff(user)){
      return OrderStatus.CONFIRMED;
    };
  };

  const getOrderListFilters = () => {
    const filters = {};
    if (searchParams.get("status") === "ARCHIVED"){
      filters["isArchived"] = {
        equals: true
      };
    }
    if (searchParams.get("status") === "TO_SHIP"){
      filters["lastShippingDate"] = {
        lte: todayTimestamp,
      }
      filters["status"] = {
        in: [OrderStatus.WAITING_FOR_PRODUCTION, OrderStatus.PRODUCING]
      }
    }
    if (
      searchParams.get('status')
        && searchParams.get('status') !== "ALL"
        && searchParams.get('status') !== "TO_SHIP"
        && searchParams.get('status') !== "ARCHIVED"
    ){
      filters['status'] = {
        equals: searchParams.get('status'),
      }
      filters['isArchived'] = {
        equals: false,
      }
    };
    if (searchText){
      filters['OR'] = [{
        orderReference: {
          contains: searchText,
          mode: "insensitive",
        },
      }, {
        patient: {
          is: {
            firstName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }, {
        patient: {
          is: {
            lastName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }, {
        user: {
          is: {
            firstName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }, {
        user: {
          is: {
            lastName: {
              contains: searchText,
              mode: "insensitive",
            }
          }
        }
      }]
    }
    return (filters);
  }

  const { data, refetch } = useQuery(
    GET_ALL_ORDERS_FOR_STAFF,
    {
      variables: {
        skip: LIMIT_PAGINATION_QUANTITY * parseInt(searchParams.get('page') ?? "0"),
        take: LIMIT_PAGINATION_QUANTITY,
        where: getOrderListFilters(),
      },
      fetchPolicy: "network-only",
    },
  );

  const startProduction = async(listOrderToUpdate: string[], providerGroupId?: string) => {
    try {
      await updateOrderStatusAndAddProvider(
        {
          variables: {
            orderIds: listOrderToUpdate,
            providerId: providerGroupId
          }
        }
      )
      refetch()
      setListOrderToUpdate([])
      notifications.show({
        title: "Orders are correctly set as producing",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set orders as producing",
        color: "red",
        message: "",
      });
    }
  };

  useEffect(() => {
    if (searchParams.get("search") && !searchText){
      setSearchText(searchParams.get("search"));
    }
  }, [searchParams.get("search")]);

  // useEffect(() => {
  //   socket.on('notification', () => {
  //     refetch();
  //   })
  // }, []);

  return (
    <div>
      <Modal
        opened={openSendToSupplierModal}
        onClose={() => setOpenSendToSupplierModal(false)}
      >
        <p style={{fontSize: '14px'}}>{`You are about to update the status of ${listOrderToUpdate.length} orders to  : ${OrderStatus.WAITING_FOR_PRODUCTION}`}</p>
        <SendOrderToSupplierModal
          suppliers={
            (allProviders?.getAllProviders ?? [])
            .map((provider: { id: string, firstname: string, lastname: string }) => {return { id: provider.id, name: provider.name }})
          }
          plural={listOrderToUpdate.length}
          onSubmit={(selectedSupplierId: string) => {
            if (!selectedSupplierId){
              notifications.show({
                title: "You must select a provider",
                color: "red",
                message: "",
              });
              return ;
            }
            startProduction(listOrderToUpdate, selectedSupplierId);
            setOpenSendToSupplierModal(false);
          }}
        />
      </Modal>
      <div style={{display:'flex'}}>
        <PrecedentComponent/>
        <SearchBar
          setSearchText={(value) => setSearchText(value)}
          searchText={searchText}
        />
        <Menu>
          <Menu.Target>
            <MantimeButton><IconClick size={'sm'} style={{marginRight: "5px"}}/> Actions</MantimeButton>
          </Menu.Target>
          <Menu.Dropdown>
            {
              Object.keys(OrderStatus)
                .filter(
                  ( status => searchParams.get('status') !== status && 
                    (isUserStaff(user) ||
                      (isUserProvider(user) && 
                        [
                          OrderStatus.WAITING_FOR_PRODUCTION,
                          OrderStatus.PRODUCING,
                          OrderStatus.PRODUCING_IN_TRANSIT,
                          OrderStatus.SHIPPED,
                          OrderStatus.ON_HOLD
                        ].includes(status)
                      )
                    )
                  )
                ).map((item : string, index : number) => 
                <Menu.Item
                  key={index}
                  onClick={
                    () => {
                      if(
                        item === OrderStatus.WAITING_FOR_PRODUCTION && (
                          searchParams.get('status') === OrderStatus.DRAFT 
                          || searchParams.get('status') === OrderStatus.CONFIRMED
                        )
                      ) {
                        setOpenSendToSupplierModal(true);
                        return
                      }
                      if(confirm("You are about to update the status of "+listOrderToUpdate.length+" orders to  : " + item)) {
                        updateOrderStatus(
                          {
                            variables: {
                              where: {
                                id: {
                                  in: listOrderToUpdate
                                },
                              },
                              data: {
                                status: {
                                  set: item,
                                },
                              },
                            },
                          }
                        ).then(()=>{
                          setListOrderToUpdate([])
                          refetch()
                        })
                      }
                    }
                  }
                >
                  {item}
                </Menu.Item>
               )
            }
          </Menu.Dropdown>
        </Menu>
      </div>
      <OrderListTable
        user={user}
        data={data?.getAllOrdersForStaff.orders ?? []}
        navigate={(url: string) => navigate(url)}
        status={searchParams.get('status')}
        setListOrderToUpdate={setListOrderToUpdate}
        toggleList={toggleList}
        selectedValues={listOrderToUpdate}
        selectAll={(orderIds: string[]) => setListOrderToUpdate([...orderIds])}
        searchText={searchText}
      />
      {((data?.getAllOrdersForStaff.orders ?? [])).length > 0 &&
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div>
            {parseInt(searchParams.get("page") ?? "0") > 0 &&
              <Button
                icon={<IconArrowLeft style={{marginRight: "5px"}}/>}
                value="Previous page"
                onClick={() => {
                  navigate(`/home?status=${getStatus()}&page=${parseInt(searchParams.get('page') ?? "0", 10) - 1}`)
                }}
              />
            }
          </div>
          <div style={{ marginTop: "1rem" }}>
            Page {(parseInt(searchParams.get("page") ?? "0")) + 1}, total: {data?.getAllOrdersForStaff.totalCount} order{data?.getAllOrdersForStaff.totalCount > 1 ? 's': ''} { listOrderToUpdate.length !== 0 && 
              <span>, {listOrderToUpdate.length} selected out of {data?.getAllOrdersForStaff.totalCount} order{data?.getAllOrdersForStaff.totalCount > 1 ? 's': ''}</span>
            }
          </div>
          <div>
            {(data?.getAllOrdersForStaff.orders ?? []).length % LIMIT_PAGINATION_QUANTITY === 0 &&
              <Button
                icon={<IconArrowRight style={{marginRight: "5px"}}/>}
                value="Next page"
                onClick={() => {
                  navigate(`/home?status=${getStatus()}&page=${parseInt(searchParams.get('page') ?? "0", 10) + 1}`)
                }}
              />
            }
          </div>
        </div>
      }
    </div>
  )
};

export default Home;
