// @ts-ignore
import React from "react";
import {
  UseFormReturn,
} from "react-hook-form";

import {
  Title,
} from "@mantine/core";

import {
  SelectorButtonRef,
  YES_OR_NO_CHOICE,
  NewFullDentureStepChoices,
  FullDentureTypeChoices,
} from "@jasper/shared";

interface FullDenturePatientProps {
  rhf: UseFormReturn,
};

const FullDenturePatient = ({
  rhf,
}: FullDenturePatientProps) => {
  const watchProductSpecificType = rhf.watch("productSpecificType");
  const watchIsReplacement = rhf.watch("isReplacement");
  const watchNewFullDentureStep = rhf.watch("newFullDentureStep");

  return (
    <>
      <Title>Typologie de prothèse adjointe complète</Title>
      <SelectorButtonRef
        label="Type de PAC"
        name="productSpecificType"
        data={FullDentureTypeChoices}
        onSubmit={(selectedValue) => rhf.setValue("productSpecificType", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchProductSpecificType}
        required={true}
      />
      <SelectorButtonRef
        label="Le patient possède-t-il déjà une PAC pré-existante?"
        name="isReplacement"
        data={YES_OR_NO_CHOICE}
        onSubmit={(selectedValue) => rhf.setValue("isReplacement", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchIsReplacement}
        required={true}
      />
      <SelectorButtonRef
        label="Choisissez une étape"
        name="newFullDentureStep"
        data={NewFullDentureStepChoices}
        onSubmit={(selectedValue) => rhf.setValue("newFullDentureStep", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchNewFullDentureStep}
        required={true}
      />
    </>
  );
};

export default FullDenturePatient;
