import { gql } from "@apollo/client";

export const UPDATE_DELIVERY_DATE = gql`
  mutation UpdateOrderDeliveryDate($orderId: String!, $newDeliveryDate: Timestamp!, $shouldUpdateAbutmentType: Boolean!) {
    updateOrderDeliveryDate(orderId: $orderId, newDeliveryDate: $newDeliveryDate, shouldUpdateAbutmentType: $shouldUpdateAbutmentType) {
      id
    }
  }
`;

export const UPDATE_MANY_ORDER = gql`
  mutation updateManyOrder($data: OrderUpdateManyMutationInput!, $where: OrderWhereInput!) {
    updateManyOrder(data: $data, where: $where)
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
    updateOrder(data: $data, where: $where) {
      id
    }
  }
`;

export const REDO_ORDER = gql`
  mutation RedoOrder($orderId: String!, $comment: String!) {
    redoOrder(orderId: $orderId, comment: $comment)
  }
`;

export const TRANSLATE_COMMENT = gql`
  mutation TranslateComment(
    $data: OrderCommentUpdateInput!,
    $where: OrderCommentWhereUniqueInput!
  ) {
    translateComment(
      data: $data,
      where: $where
    )
  }
`;

export const GET_ORDER_BY_UNIQUE_ATTRIBUTE = gql`
  query GetOrderByUniqueAttribute($where: OrderWhereUniqueInput!) {
    getOrderByUniqueAttribute(where: $where) {
      id
      orderReference
      comment
      deliveryDate
      status
      createdAt
      provider {
        name
      }
      patient {
        firstName
        lastName
        birthdate
        sex
      }
      files {
        id
        fileType
        key
        read
      }
      user {
        id
        lastName
        firstName
      }
      orderComment {
        id
        translatedComment
        comment
        type
        files {
          id
          fileType
          key
          read
        }
      }
      products {
        id
        productType
        teeth
        itemPreferences
        implantItem {
          id
          itemType
          teeth
          itemMaterial {
            id
            label
          }
          itemShade {
            id
            label
          }
          itemBilling {
            firstMaterialLotNumber
            firstMaterialTraceabilityId
            id
          }
          abutmentMaterial {
            id
            label
          }
          abutmentType
          implantPlatform
          implantReference {
            brand
            label
            id
            platforms
          }
          retentionType
          scanBodySuggestion
          standardAbutmentSuggestion
          implantReferenceSuggestion
          teethshadeType
          multiShadeInfo {
            teethShadeSide
            itemShade {
              label
            }
          }
        }
        anatomyItem {
          id
          itemType
          teeth
          itemShade {
            id
            label
          }
          itemMaterial {
            id
            label
          }
          inlayCoreHasClavette
          inlayCoreScanPost
          inlayCoreMaterial {
            id
            label
          }
          itemBilling {
            firstMaterialLotNumber
            firstMaterialTraceabilityId
            id
          }
          teethshadeType
          multiShadeInfo {
            teethShadeSide
            itemShade {
              label
            }
          }
        }
        removableItem {
          id
          itemType
          teeth
          teethToManufacture
          workflowType
          dentistNotes
          isReplacement
          itemMaterial {
            id
            label
          }
          teethShade {
            id
            label
          }
          gingivaShade {
            id
            label
          }
          itemBilling {
            firstMaterialLotNumber
            firstMaterialTraceabilityId
            id
          }
        }
      }
    }
  }
`;
