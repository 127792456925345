// @ts-ignore
import React, { useEffect, useState } from "react";
import { Stepper, Button, Group } from '@mantine/core';
import { useForm } from "react-hook-form";

import FullDenturePatient from "./fullDenturePatient";
import FullDentureDetails from "./fullDentureDetails";

import {
  ProductPreferences,
  AnatomyItemChoicesData,
  GingivaShade,
  Order,
} from "../../../../types/interfaces";
import {
  STYLE,
} from "../../../../types/constants";
import {
  ItemType,
} from "../../../../types/enums";
import {
  deduceShadeType,
  isFullDentureType,
} from '../../../../utils/item.utils';
import {
 FullDentureWorkflowToSubmit,
 } from "./fullDentureInterfaces";

interface FullDentureWorkflowProps {
  user_id: string,
  accountProductPreferences: ProductPreferences,
  anatomyItemChoicesData: AnatomyItemChoicesData,
  gingivaShadeChoices: GingivaShade[],
  order: Order,
  onSubmit: (data: FullDentureWorkflowToSubmit) => Promise<void>,
};

const FullDentureWorkflow = ({
  user_id,
  accountProductPreferences,
  anatomyItemChoicesData,
  gingivaShadeChoices,
  onSubmit,
  order,
}: FullDentureWorkflowProps) => {

  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const savedItem = (order?.products ?? []).length > 0
    ? (order?.products[0]?.removableItem ?? []).find((item) => isFullDentureType(item.itemType))
    : undefined;

  const rhf = useForm<FullDentureWorkflowToSubmit & { material: string }>({
    defaultValues: {
      isReplacement: savedItem?.isReplacement,
      material: savedItem?.itemMaterial?.id,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
      shade: savedItem?.teethShade?.id,
      gingivaShade: savedItem?.gingivaShade?.id,
      productSpecificType:
        savedItem?.itemType === ItemType.IMMEDIATE_DENTURE
          ? ItemType.IMMEDIATE_DENTURE
          : ItemType.FULL_DENTURE,
      newFullDentureStep:
        savedItem?.itemType === ItemType.FULL_DENTURE_WAX_BITE ? 1 : 2,
      teethShape: (order?.products ?? []).length > 0
        ? (order?.products[0])?.itemPreferences?.set?.teethShape
        : undefined,
    },
  });

  useEffect(() => {
    if (savedItem){
      rhf.setValue("isReplacement", savedItem?.isReplacement);
      rhf.setValue("material", savedItem?.itemMaterial?.id);
      rhf.setValue("shadeType", deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences));
      rhf.setValue("shade", savedItem?.teethShade?.id);
      rhf.setValue("gingivaShade", savedItem?.gingivaShade?.id);
      rhf.setValue("teethShape", (order?.products[0])?.itemPreferences?.set?.teethShape);
    }
  }, [savedItem]);

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Patient">
          <FullDenturePatient
            rhf={rhf}
          />
        </Stepper.Step>
        <Stepper.Step label="Typologie">
          <FullDentureDetails
            rhf={rhf}
            user_id={user_id}
            accountProductPreferences={accountProductPreferences}
            anatomyItemChoicesData={anatomyItemChoicesData}
            gingivaShadeChoices={gingivaShadeChoices}
          />
        </Stepper.Step>
      </Stepper>
      <Group justify="space-between" mt="xl">
        <div>
          {active !== 0 &&
            <Button variant="default" onClick={prevStep}>Back</Button>
          }
        </div>
      <Button onClick={active !== 1 ? nextStep : rhf.handleSubmit(onSubmit)} style={{ backgroundColor: STYLE.primary }}>Next step</Button>
      </Group>
    </>
  );
};

export default FullDentureWorkflow;
