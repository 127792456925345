import {
  useSearchParams,
  useParams
} from "react-router-dom";
import {
  useQuery,
  useMutation,
} from "@apollo/client";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import CreateNewInlayCoreComponent from './CreateNewInlayCoreComponent';
import CreateNewLay from './CreateNewLay';
import CreateNewCrown from './CreateNewCrown';
import CreateNewSplint from './removable-items/CreateNewSplint';
import PartialDentureWorkflow from './removable-items/partial-denture/PartialDentureworkflow';
import FullDentureWorkflow from "./removable-items/full-denture/fullDentureWorkflow";

import {
  ItemType,
  TeethShadeType,
  ItemShadeType,
  TeethShadeSide,
} from "../../types/enums";
import {
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
} from "../../gql/orders";
import {
  ANATOMY_ITEM_CHOICE_DATA,
  CREATE_ANATOMY_ITEM,
  UPDATE_ANATOMY_ITEM,
  UPDATE_REMOVABLE_ITEM,
  CREATE_REMOVABLE_ITEM,
  DELETE_MANY_REMOVABLE_ITEMS,
} from "../../gql/items";
import {
  UPDATE_PRODUCT,
} from "../../gql/product";
import {
  GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES,
} from "../../gql/users";
import {
  getAnatomyItemMaterialChoices,
  getAnatomyItemChoiceWhere,
} from "../../utils/product.utils";
import {
  getTeethsFromUpperArch,
  getTeethsFromLowerArch,
  deduceFinalItemTypeOfFullDenture,
  isFullDentureType,
  getGingivaShadeFromAllShades,
} from "../../utils/item.utils";
import {
  FullDentureWorkflowToSubmit,
} from "./removable-items/full-denture/fullDentureInterfaces";

const NewItemComponent = () => {
  const [searchParams] = useSearchParams();
  const { product_id, order_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const productType = searchParams.get("itemType");
  const itemId = searchParams.get("itemId");

  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);
  const [updateAnatomyItem] = useMutation(UPDATE_ANATOMY_ITEM);
  const [createRemovableItem] = useMutation(CREATE_REMOVABLE_ITEM);
  const [updateRemovableItem] = useMutation(UPDATE_REMOVABLE_ITEM);
  const [deleteManyRemovableItem] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  const { data: order, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id: order_id,
        }
      }
    }
  );
 
  const { data: anatomyItemChoicesData } = useQuery(
    ANATOMY_ITEM_CHOICE_DATA,
    {
      variables: {
        where: getAnatomyItemChoiceWhere(productType),
      },
      fetchPolicy: "network-only"
    }
  );
  const { data: accountProductPreferences } = useQuery(GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES);

  const materialFormChoices = anatomyItemChoicesData
    ? getAnatomyItemMaterialChoices(anatomyItemChoicesData, productType)
    : []
  const product = (order?.getOrderByUniqueAttribute?.products ?? []).find((product) => product.id === product_id);

  switch(productType){
    case ItemType.INLAY_CORE:
      return (
        <CreateNewInlayCoreComponent
          productType={productType}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
          itemId={itemId}
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
        />
      );
    case ItemType.LAY:
    case ItemType.INLAY:
    case ItemType.ONLAY:
    case ItemType.OVERLAY:
      return (
        <CreateNewLay
          productType={productType}
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
        />
      );
    case ItemType.SPLINT:
      return (
        <CreateNewSplint
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
        />
      );
    case ItemType.CROWN:
      return (
        <CreateNewCrown
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          onSubmit={async(data, watchIsMultiShade, item) => {
            if (item?.id){
              if ((item?.multiShadeInfo ?? []).length > 0){
                await updateAnatomyItem({
                  where: {
                    id: item.id
                  },
                  data: {
                    multiShadeInfo: {
                      deleteMany: [
                        {
                          id: {
                            in: [(item?.multiShadeInfo).map((multiShadeInfo) => multiShadeInfo.id)]
                          }
                        }
                      ]
                    }
                  }
                })
              }
              await updateAnatomyItem({
                variables: {
                  where: {
                    id: item.id
                  },
                  data: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                    teeth: product.teeth,
                    teethshadeType: watchIsMultiShade
                      ? {
                        set: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade
                      } : undefined,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.Incisal,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  }
                }
              });
            } else {
              await createAnatomyItem({
                variables: {
                  args: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                    itemType: ItemType.CROWN,
                    teeth: product.teeth,
                    product: {
                      connect: {
                        id: product.id,
                      },
                    },
                    teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.Incisal,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  },
                },
              });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    case ItemType.FULL_DENTURE:
    case ItemType.IMMEDIATE_DENTURE:
    case ItemType.FULL_DENTURE_WAX_BITE:
      return (
        <FullDentureWorkflow
          user_id={order?.getOrderByUniqueAttribute?.user?.id}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          gingivaShadeChoices={getGingivaShadeFromAllShades(anatomyItemChoicesData?.getItemShadesWhere ?? [])}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          order={order?.getOrderByUniqueAttribute}
          onSubmit={async(data: FullDentureWorkflowToSubmit) => {
            const savedItem = (location?.state?.product?.removableItem ?? [])
              .find((item: { itemType: ItemType }) => isFullDentureType(item.itemType));
            await updateProduct({
              variables: {
                where: {
                  id: product.id,
                },
                data: {
                  itemPreferences: {
                    set: {
                      teethShape: data.teethShape,
                    }
                  },
                },
              },
            });
            if (savedItem){
              await updateRemovableItem({
                variables: {
                  where: {
                    id: savedItem?.id,
                  },
                  data: {
                    teethShade: {
                      connect: {
                        id: data.shade,
                      }
                    },
                    teeth: product.teeth,
                    product: {
                      connect: {
                        id: product.id,
                      }
                    },
                    isReplacement: {
                      set: data.isReplacement
                    },
                    teethToManufacture: {
                      set: product.teeth,
                    },
                    itemType: {
                        set: deduceFinalItemTypeOfFullDenture(data.productSpecificType, data.newFullDentureStep, data.isReplacement)
                    },
                    gingivaShade: {
                      connect: {
                        id: data.gingivaShade,
                      }
                    },
                  },
                }
              })
            }
            else {
              await createRemovableItem({
                variables: {
                  args: {
                    teethShade: {
                      connect: {
                        id: data.shade,
                      }
                    },
                    product: {
                      connect: {
                        id: product.id,
                      }
                    },
                    teeth: product.teeth,
                    teethToManufacture: {
                      set: product.teeth,
                    },
                    isReplacement: data.isReplacement,
                    itemType: deduceFinalItemTypeOfFullDenture(data.productSpecificType, data.newFullDentureStep, data.isReplacement),
                    gingivaShade: {
                      connect: {
                        id: data.gingivaShade,
                      }
                    },
                  }
                }
              });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    case ItemType.PARTIAL_DENTURE:
      return (
        <PartialDentureWorkflow
          materialChoices={materialFormChoices ?? []}
          gingivaShadeChoices={(anatomyItemChoicesData?.getItemShadesWhere ?? []).filter((shade) => shade.shadeType === ItemShadeType.GINGIVA).map((shade) => ({ value: shade.id, label: shade.label }))}
          order={order?.getOrderByUniqueAttribute}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          onSubmit={async(values)  => {
            const savedItem = (location?.state?.product?.removableItem ?? []).find((item) => item.itemType === ItemType.PARTIAL_DENTURE);
            const upperTeeth = getTeethsFromUpperArch(values?.teeth ?? []);
            const lowerTeeth = getTeethsFromLowerArch(values?.teeth ?? []);
            await deleteManyRemovableItem({
              variables: {
                where: {
                  productId: {
                    equals: location?.state?.product?.id,
                  }
                }
              },
            });
            if (upperTeeth.length > 0){
              await createRemovableItem({
                variables: {
                  args: {
                    teeth: upperTeeth,
                    teethShade: {
                      connect: {
                        id: values.shade,
                      }
                    },
                    teethToManufacture: {
                      set: upperTeeth,
                    },
                    workflowType: values.workflowType,
                    product: {
                      connect: {
                        id: product.id,
                      }
                    },
                    isReplacement: values.isReplacement,
                    itemMaterial: {
                      connect: {
                        id: values.material,
                      }
                    },
                    itemType: ItemType.PARTIAL_DENTURE,
                    gingivaShade: {
                      connect: {
                        id: values.gingivaShade,
                      }
                    },
                    dentistNotes: values.hookComment,
                  },
                }
              });
            }
            if (lowerTeeth.length > 0){
              await createRemovableItem({
                variables: {
                  args: {
                    teeth: lowerTeeth,
                    teethShade: {
                      connect: {
                        id: values.shade,
                      }
                    },
                    teethToManufacture: {
                      set: lowerTeeth,
                    },
                    workflowType: values.workflowType,
                    product: {
                      connect: {
                        id: product.id,
                      }
                    },
                    isReplacement: values.isReplacement,
                    itemMaterial: {
                      connect: {
                        id: values.material,
                      }
                    },
                    itemType: ItemType.PARTIAL_DENTURE,
                    gingivaShade: {
                      connect: {
                        id: values.gingivaShade,
                      }
                    },
                    dentistNotes: values.hookComment,
                  },
                }
              });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      )
    default:
      return "undefined";
  }
};

export default NewItemComponent;
